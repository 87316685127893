<template>
  <div class="headMain">
    <div class="head-box">
    <!-- flex_start flex_center_align  -->
      <div class="left_box flex_colum">
      <div>
        <span class="title">{{ year }}</span>
        <!-- <span class="title" style="margin-left:20px;cursor: pointer;" @click="dataCenter">综合治理与服务平台</span> -->
        </div>
        <dv-decoration-4 :reverse="true" style="width:500px;height:10px;" :dur="6" />
      </div>
      <div class="head-item">
      <div class="head-item-top">
        <img class="head-img" :src="headName[0].titleIcon" v-if="headName[0].titleIcon" />
        <div class="head-center" v-if="headName[0]">
          <div class="head-title" :class="{'lettle':headName[0].name=='小平房村数字乡村综合治理与服务平台'}">
            {{ headName[0].name }}
          </div>
          <div>{{ headName[0].enName }}</div>
        </div>
        </div>
        <dv-decoration-5 style="width:620px;height:40px;" :dur="6"/>
      </div>
      <div class="right_box">
        <div class="flex_start flex_center_align tian_box">
          <div class="flex_start flex_center_align" v-if="tianInfo.weather">
            <span class="fengtitle">{{ tianInfo.weather }} {{ tianInfo.temperature }} {{ tianInfo.wind }}</span>
          </div>
          <div class="flex_start flex_center_align log_btn" @click="goLogin" v-if="!status">登录</div>
          <div class="flex_start flex_center_align log_btn" @click="goOut" v-else>退出</div>
        </div>
        <!-- width:100%; -->
        <dv-decoration-4 :reverse="true" style="width:500px;height:5px;" :dur="6" />
      </div>
      <!-- <div class="tian_box">
      <div>
      <div class="flex_start flex_center_align ">
        <div class="flex_start flex_center_align" v-if="tianInfo.weather">
          <span class="fengtitle">{{ tianInfo.weather }} {{ tianInfo.temperature }} {{ tianInfo.wind }}</span>
        </div>
        <div class="flex_start flex_center_align log_btn" @click="goLogin" v-if="!status">登录</div>
        <div class="flex_start flex_center_align log_btn" @click="goOut" v-else>退出</div>
        </div>
        <dv-decoration-4 :reverse="true" style="width:76%;height:5px;" />
      </div>
      </div> -->
      <div class="loginabox" v-if="status && form.addres">
        <div class="loginAds">{{ form.addres }}<span v-if="headName[0].route==='videos'">监控分布图</span></div>
      </div>
    </div>

    <h-login ref="loginmode" @login="submitForm"></h-login>
  </div>
</template>
<script>
import { getTianQi, getTianQiNew } from '@/api/api.js'
import hLogin from '@/components/login.vue'
import { navlist } from '@/utils/index.js'
export default {
  props: ['info', 'date', 'type', 'title'],
  components: {
    hLogin
  },
  data() {
    return {
      year: '',
      tianInfo: {},
      status: false,
      form: {},
      navlist: []
    }
  },
  computed: {
    headName() {
      let form = [{ name: '小平房村数字乡村综合治理与服务平台', enName: 'Xiaopingfang Rural Comprehensive Governance and Service Center' }]
      if (this.navlist && this.title != 'home') {
        form = this.navlist.filter(i => {
          return i.route == this.title
        })
      } else {
        form = [{ name: '小平房村数字乡村综合治理与服务平台', enName: 'Xiaopingfang Rural Comprehensive Governance and Service Center' }]
      }
      return form
    }
  },
  methods: {
    goLogin() {
      this.$refs.loginmode.show = true
    },
    //退出
    goOut() {
      this.num = 0
      sessionStorage.clear()
      this.form = {}
      this.status = false
      this.$router.replace('/')
      // 返回
      // if (window.history.length <= 1) {
      //   this.$router.replace({ path: '/' })
      //   return false
      // } else {
      //   this.$router.go(-1)
      //   return
      // }
    },
    getTianQi() {
      // https://tianqiapi.com/free/day?appid=89542129&appsecret=0ISwPD4f&unescape=1&cityid=101071201
      let data = {
        appid: '89542129',
        appsecret: '0ISwPD4f',
        unescape: 1,
        cityid: '101071201'
      }
      getTianQi(data).then(res => {
        getTianQiNew({ cityId: res.cityid }).then(res => {
          this.tianInfo = res.model
        })
      })
    },
    //登录
    submitForm() {
      this.status = true
    },
    dataCenter() {
      this.$router.push('/dataIftame')
    }
  },
  mounted() {
    //  this.$refs.loginmode.show = true
    this.navlist = navlist
    // this.getTianQi()
    window.addEventListener('setItem', () => {
      this.status = true
      this.form = JSON.parse(sessionStorage.getItem('userInfo'))
      this.$forceUpdate()
    })
    //登录账户信息缓存中有登录按钮隐藏
    if (sessionStorage.getItem('userInfo')) {
      this.form = JSON.parse(sessionStorage.getItem('userInfo'))
      this.submitForm()
      this.$forceUpdate()
    }
  },
  created() {
    this.year = this.$moment().format('YYYY-MM-DD')
    this.getTianQi()
  }
}
</script>
<style lang="scss" scope>
.flex_start {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
}
.flex_space {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.flex_center {
  display: flex !important;
  justify-content: center;
}
.flex_center_align {
  display: flex !important;
  align-items: center !important;
}
.headMain {
  z-index: 9999999999999;
}
.head-box {
  width: 100%;
  height: 128px;
  background: linear-gradient(rgba(53, 53, 55, 64%) 0%, rgba(53, 53, 55, 0) 100%);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-size: 16px;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  .left_box,
  .tian_box {
    // margin-top: 21px;
    flex: 1;
  }
  .left_box {
    margin-left: 40px;
  }
  .tian_box {
    // margin-right: 40px;
    justify-content: flex-end !important;
  }
  .log_btn {
    margin-left: 15px;
    cursor: pointer;
  }
  .loginabox {
    position: inherit;
    top: 115px;
    left: 35.1%;
    text-align: center;
    width: 29.8%;
    // margin-left: -14.9%;
  }
  .loginAds {
    width: 100%;
    margin: 0 auto;
    // background: url('../assets/img/headbg1.png');
    background-size: 100% 100%;
    background-origin: border-box;
    background-repeat: no-repeat;
    padding: 3px 0;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.16em;
  }
  .head-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .head-item-top {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: -10px;
    }
  }
  .head-img {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }
  .head-center {
    text-align: center;
    font-family: 'YouSheBiaoTiHei';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    // flex: 1;
  }
  .head-title {
    letter-spacing: 0.16em;
    font-size: 48px;
    color: #0099ff;
    text-shadow: 0 0 15px#0099ff;
  }
  .bigSize {
    font-size: 46px;
  }
  .right_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 40px;
  }
  .lettle {
    font-size: 30px;
  }
}
</style>
