<template>
  <div class="panelMain">
    <div class="panel" :style="{width:width,height:height,marginLeft:marginLeft,marginTop:marginTop,marginRight:marginRight,marginBottom:marginBottom}">
      <div class="panel_img" :style="{width:width,height:height}" v-if="lineShow">
        <img src="@/assets/img/line1.png" style="width:100%;height:100%">
      </div>
      <div class="panel_con bg" :style="pstyle">
        <!-- <div v-if="title">{{title}}</div> -->
        <slot>
        </slot>
      </div>

      <div class="panel_img" :style="{width:width,height:height}" v-if="lineShow">
        <img src="@/assets/img/line1.png" style="width:100%;height:100%">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Hpanel',
  props: [
    'lineShow',
    'title',
    'position',
    'width',
    'height',
    'icon',
    'top',
    'bg',
    'pstyle',
    'marginLeft',
    'marginTop',
    'marginRight',
    'marginBottom',
  ],

  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.panel_img {
  display: contents;
  width: 100%;
  height: 1px;
  // max-width: 562px;
}
.panel {
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 562px; */
}
/* .panel_con {
  overflow-y: inherit;
  overflow-x: hidden;h
} */
.bg {
  width: 100%;
  height: 100%;
  background: rgba(2, 0, 77, 0.7);
  border: 1px solid #0d53b7;
  box-shadow: inset 0px 0px 34px rgba(17, 40, 255, 0.66);
  border-radius: 10px;
  margin: 3px auto;
  position: relative;
}
.panel_con .con_head {
  justify-content: space-between;
  font-size: 16px;
  width: 88%;
  margin: 15px auto;
}
.p-title {
  /* margin: 8px auto; */
  text-align: center;
  width: 100%;
  font-size: 18px;
  letter-spacing: 4px;
}
.head-img {
  width: 12px;
  height: 12px;
  margin: 0 5px;
}
.panelMain .panel_con::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.panelMain .panel_con::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(29, 133, 197, 1);
  -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1);
  background: #535353;
}

.panelMain .panel_con::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #ededed;
}
</style>