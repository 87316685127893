<template>
  <div class="mode_main">
    <div class="mode_content" :class="`${bg} ${position} ${borderRadious}`" :style="{ width:`calc(${width} - 20px)`, height: `calc(${height} - 20px)`, top: top }">
      <!-- <div class="mode_img mode_top_img">
        <img src="@/assets/img/line1.png" alt="" style="width:100%;height:100%" />
      </div> -->  
      <dv-border-box-1>

      <div class="new_con">
        <div class="flex_space header_box flex_center_align" v-if="title">
          <div class="header_title">
            <div class="flex_start" style="align-items:center">
              <img class="iconImg" :src="baseURL + icon" alt="" v-if="icon"/>
              <span class="title">{{ title }}</span>
            </div>
          </div>
          <template v-if="goInfoTemp">
            <img class="jinru" src="@/assets/img/jinru.png" alt="" @click="goInfo" />
          </template>
        </div>
        <div :class="[szType != 'grid' ? 'scroll' : 'gridscroll']">
          <slot name="index"></slot>
          <slot name="grid"></slot>
        </div>
      </div>
      </dv-border-box-1>
      <!-- <div class="mode_img"><img src="@/assets/img/line1.png" alt="" style="width:100%;height:100%" /></div> -->
    </div>
  </div>
</template>

<script>
import bus from '../common/js/bus'
export default {
  props: ['bg', 'position', 'width', 'height', 'icon', 'title', 'goInfoTemp', 'dIndex', 'top', 'szType'],
  data() {
    return {
      baseURL: process.env.VUE_APP_API
    }
  },
  // computed:{
  //   calWidth(){
  //     return cal
  //   }
  // },
  methods: {
    goInfo() {
      bus.$emit('goInfo', this.dIndex, this.title)
    }
  },
  computed: {
    borderRadious() {
      let str = this.bg.split('_')
      if (str[0] == 'left') {
        return 'borderrightradius'
      } else if (str[0] == 'right') {
        return 'borderleftradius'
      }
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.mode_main {
  position: absolute;
  width: 100%;
  top: 74px;
  bottom: 49px;
  color: #fff;
  /deep/.ql-editor {
    line-height: 1.8 !important;
    padding: 30px 0 0 0 !important;
    p span {
      color: #ccc !important;
    }
  }
}
.mode_content {
  position: absolute;
  z-index: 4;
  // background: rgba(37, 37, 39, 0.8);
  background: rgba(0, 50, 100, 0.5);
  border-radius: 20px;
  padding: 10px;

  .scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(90% - 54px);
    width: 92%;
    margin: 0 auto;
  }
  .gridscroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 20px);
    margin: 10px auto;
  }
  // &.left_bg {
  //   background: linear-gradient(270deg, rgba(20, 27, 45, 0.7) 0%, rgba(2, 7, 17, 0.7) 100%);
  // }
  // &.right_bg {
  //   background: linear-gradient(90deg, rgba(20, 27, 45, 0.7) 0%, rgba(2, 7, 17, 0.7) 100%);
  // }
  // &.borderrightradius {
  //   border-top-right-radius: 2px;
  //   border-bottom-right-radius: 2px;
  // }
  // &.borderleftradius {
  //   border-top-left-radius: 2px;
  //   border-bottom-left-radius: 2px;
  // }
  // &.left_bg::before {
  //   contain: '';
  //   width: 100%;
  //   height: 2px;
  //   background: url('~@/assets/img/line1.png') bottom left no-repeat;
  // }
  &.leftTop {
    left: 40px;
    top: 0;
  }
  &.leftBottom {
    left: 40px;
    bottom: 0;
  }
  &.rightTop {
    right: 40px;
    top: 0;
  }
  &.rightBottom {
    right: 40px;
    bottom: 0;
  }
  &.left-top {
    left: 40px;
    top: 0;
    // bottom: 20px;
  }
  &.left-bottom {
    left: 40px;
    bottom: 0;
  }
  &.right-top,
  &.right {
    right: 40px;
    top: 0;
  }
  &.right-bottom {
    right: 40px;
    bottom: 0;
  }

  .header_box {
    padding: 28px 8px 25px;
    .header_title {
      width: 170px;
      flex: 1;
      .title {
        color: #fff;
        font-family: 'YouSheBiaoTiHei';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
      }
      .iconImg {
        height: 39px;
        margin-right: 13px;
      }
    }
    .jinru {
      width: 14px;
      height: 16px;
      margin-left: 50px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}
.scroll::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.scroll::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #0d53b7;
  -webkit-box-shadow: inset 0 0 5px #0d53b7;
  background: #535353;
}

.scroll::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: transparent;
}
.new_con {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.mode_img {
  width: 100%;
  height: 2px;
  margin: 3px auto;
  display: grid;
}
</style>
