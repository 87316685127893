<template>
  <div class="footbox">
    <div class="foot_bot">
      <div class="f-bottom">
        <div class="footHome" @click.stop="tabShow('back')" v-if="status!='grid'">
          <img src="../assets/img/home.png" alt="" style="width:100%;height:100%" />
        </div>
      </div>

      <!-- <div class="f-bottom" v-if="type!='grid'">
        <div class="footHome" @click.stop="tabShow">
          <img src="../assets/img/home.png" alt="" style="width:100%;height:100%">
        </div>
      </div> -->
      <div width="562px" :lineShow="true" style="float:right">
        <div class="bottom">
          <div class="tab_title" :class="activeClass == index ? 'tab_title-item' : ''" handleTab v-for="(item, index) in tabList" :key="index" @click.stop="handlerTab(index)">
            <div class="tab_icon"><img :src="item.icon ? item.icon : require('@/assets/img/star.png')" alt="" style="width:100%;height:100%" /></div>
            <div class="tab_name">{{ item.tabName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HPanel from './panel.vue'
export default {
  components: {
    HPanel
  },
  props: ['tabList', 'date', 'status', 'type'],
  data() {
    return {
      num: 0,
      tShow: false,
      activeClass: '0'
    }
  },
  methods: {
    tabShow(type) {
      if (type == 'back') {
        this.$router.replace('/')
        // if (window.history.length <= 1) {
        //   this.$router.push({ path: '/' })
        //   return false
        // } else {
        //   this.$router.go(-1)
        //   return
        // }
      } else {
        this.tShow = !this.tShow
      }
    },
    handlerTab(index) {
      this.activeClass = index
      // console.log("item",item);
      if (index != this.num || (index == 0 && this.num == 0)) {
        this.num = index
        this.$emit('tab', index)
      }
    }
  }
}
</script>
<style lang="scss" scope>
.flex_start {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
}
.flex_space {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.flex_center {
  display: flex !important;
  justify-content: center;
}
.flex_center_align {
  display: flex !important;
  align-items: center !important;
}
.footbox {
  width: 45%;
  height: auto;
  position: fixed;
  right: 26.5%;
  bottom: 23px;
  clear: both;
  z-index: 998;
  .f-bottom {
    width: 102px;
    height: 102px;
    float: right;
  }
  .footHome {
    width: 79px;
    height: 79px;
    position: relative;
    cursor: pointer;
    margin-top: 12px;
  }
  .footHome::before {
    /* border: 2px solid var(--lineColor); */
    border: 1px solid transparent;
    border-left: 1px solid rgba(75, 74, 74, 0.6);
    border-right: 1px solid rgba(75, 74, 74, 0.6);
    border-radius: 50%;
  }
  .footHome::before {
    content: '';
    width: 102px;
    height: 102px;
    position: absolute;
    top: -13px;
    left: -13px;
    animation: turn2 3.35s linear infinite;
  }
  .footHome::before {
    animation: turn2 3.35s linear infinite;
  }
  @keyframes turn2 {
    100% {
      transform: rotateZ(1turn);
    }
  }
  .bottom {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .tab_title {
    width: 100px;
    height: 75px;
    margin: 10px 5px;
    // background: rgba(2, 0, 77, 0.8);
    background: linear-gradient(180deg, rgb(81, 81, 81), rgba(0, 0, 0, 0.1));
    border: 1px solid #5a5b5d;
    // box-shadow: inset 0px 0px 34px rgbA(17, 40, 255, 66%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .tab_title:hover {
    // background: linear-gradient(180deg, #c3c3c3 0%, rgba(153, 154, 155, 17%) 100%);
    box-shadow: inset 0px 0px 15px 5px #0099ff;
  }
  .foot_bot {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .tab_icon {
    width: 24px;
    height: 22.7px;
    margin-bottom: 4px;
  }
  .tab_name {
    color: #fff;
    font-size: 14px;
  }
  .moveon {
    position: relative;
  }
  .tab_picon {
    position: absolute;
    top: -15px;
    left: 10px;
    width: 86px;
    height: 86px;
  }
  .tabon {
    font-weight: 600;
    font-size: 28px;
  }
  .bottom {
    z-index: 1;
  }
  .tab_title-item {
    background: linear-gradient(180deg, rgba(0, 50, 100, 0.8), #0099ff);
    border: 1px solid#0099ff;
    // box-shadow: inset 0px 0px 10px 5px rgba(153, 154, 155, 17%);
  }
}
</style>
