<template>
  <div class="wgmode" v-if="show">
    <div class="cun_inner" :class="['history','historyArtic'].includes(paramsData.itemtype)?'new_inner':''">
      <div class="cun_title">{{ title }}</div>
      <div class="close" @click="close">×</div>
      <!-- :class="{redcolor:title}" -->
      <!-- <div class="title">人口详情</div> -->
      <div class="box" v-if="!paramsData.itemtype">
        <el-table stripe v-loading="loading" :data="wgList" height="400">
          <el-table-column label="家庭角色" align="center" prop="relationName" />
          <el-table-column label="姓名" align="center" prop="residentName" />
          <el-table-column label="性别" align="center" prop="sexEnum" />
          <el-table-column label="身份证号" align="center" prop="idCard" />
          <el-table-column label="年龄" align="center" prop="residentAge" />
          <el-table-column label="联系电话" align="center" prop="mobile" />
          <el-table-column label="流动人口" align="center" prop="carNo">
            <template slot-scope="scope">
              <span v-if="scope.row.familyType == 2">是</span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column label="车牌号" align="center" prop="carNo" />
          <el-table-column label="有线" align="center" prop="wired" :formatter="valueFormat" />
          <el-table-column label="宽带" align="center" prop="wideBand" :formatter="valueFormat1" />
          <!-- <el-table-column label="是否在外人口" align="center" prop="lineDesc" /> -->
        </el-table>
      </div>
      <div class="box" v-else-if="paramsData.itemtype == 'grider'">
        <el-table stripe v-loading="loading" :data="wgList" height="400">
          <el-table-column label="姓名" align="center" prop="fullName" />
          <el-table-column label="身份证号" align="center" prop="idCard" />
          <el-table-column label="联系电话" align="center" prop="mobile" />
          <el-table-column label="家庭住址" align="center" prop="addresDetails" />
        </el-table>
      </div>
      <div class="box" v-else-if="paramsData.itemtype == 'yztype'">
        <el-table stripe v-loading="loading" :data="wgList" height="400">
          <el-table-column label="养殖户姓名" align="center" prop="name" />
          <el-table-column label="养殖数量" align="center" prop="num" />
        </el-table>
      </div>
      <div class="box new_box" v-else-if="paramsData.itemtype == 'history'">
        <div class="title">{{ paramsData.historyName }}</div>
      {{paramsData.itemtype}}
        <div class="word ql-editor" style="white-space:pre-wrap;" v-html="paramsData.historyText"></div>
        <div class="flex_start imgbox">
          <div class="img_item flex_center_align flex_center video_new" v-if="paramsData.historyVideo">
            <video controls="controls" autoplay class="video" :src="baseURL + paramsData.historyVideo"></video>
          </div>
          <!-- v-for="(item, index) in imgList" :key="index" -->
          <div class="img_item flex_center_align flex_center img_new" v-if="paramsData.historyImg">
            <img :src="baseURL + paramsData.historyImg" alt="" />
          </div>
        </div>
      </div>
      <div class="box" v-else-if="paramsData.itemtype == 'historyArtic'">
        <div class="title">{{ paramsData.articleTitle }}</div>
        <div class="word ql-editor" style="white-space:pre-wrap;" v-html="paramsData.content.articleContent"></div>
        <div class="flex_start imgbox">
          <div class="img_item flex_center_align flex_center video_new" v-if="paramsData.videoFile">
            <video controls="controls" autoplay class="video" :src="baseURL + paramsData.videoFile"></video>
          </div>
          <!-- v-for="(item, index) in imgList" :key="index" -->
          <div class="img_item flex_center_align flex_center img_new" v-if="paramsData.articleImg">
            <img :src="baseURL + paramsData.articleImg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      word: '',
      imgList: [],
      title: '',
      paramsData: '',
      lx: '',
      tableList: [],
      content: '',
      wgList: [],
      loading: false,
      baseURL: process.env.VUE_APP_API
    }
  },
  methods: {
    valueFormat(row, column) {
      if (row.wired == 1) {
        return '有'
      } else if (row.wired == 0) {
        return '无'
      } else {
        return ''
      }
    },
    valueFormat1(row, column) {
      if (row.wideBand == 1) {
        return '有'
      } else if (row.wideBand == 0) {
        return '无'
      } else {
        return ''
      }
    },
    initData(data) {
      console.log(data)
      this.loading = true
      this.paramsData = data
      // this.dataType = data.type
      if (this.paramsData.itemtype == 'yztype') {
        this.title = data.itemttitle || ''
      }
      this.word = data.content
      this.content = data.articleContent || data.introduction || data.word
      // this.imgList = data.imgList;
      this.show = true
      this.lx = data.elementType ? data.elementType : ''

      this.loading = false
      // this.tableList = data.tableList;
    },
    close() {
      this.show = false
      this.wgList = []
    }
  }
}
</script>

<style lang="scss" scoped>
.wgmode {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
  .cun_inner {
    width: 90%;
    border: 1px solid #959292;
    border-radius: 10px;
    background: rgba(37, 37, 39, 0.8);
    position: absolute;
    left: 5%;
    top: 50%;
    margin-top: -240px;
    z-index: 10000;
    animation-name: small-to-big;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    @keyframes small-to-big {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
    .cun_title {
      color: #fff;
      margin: 20px;
      font-size: 20px;
    }
    .title {
      font-size: 22px;
      color: #fff;
      text-align: left;
      padding: 10px 20px;
    }
    .box {
      padding: 0 20px;
      max-height: 490px;
      overflow-y: auto;
      margin-bottom: 20px;
      .title {
        white-space: pre-wrap;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
      }
      .word {
        font-size: 16px;
        color: #fff;
        line-height: 30px;
        // text-indent: 2em;
        video {
          width: 180px;
          height: 180px;
        }
        img {
          width: 180px;
          height: 180px;
          background: #adffe6;
          border: 4px solid #707070;
          border-radius: 2px;
          margin-left: 10px;
          margin-bottom: 20px;
        }
      }
      .word /deep/ img {
        width: 96%;
        height: 100%;
        background: #adffe6;
        border: 4px solid #707070;
        border-radius: 2px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
      .word /deep/ video {
        width: 96%;
        height: 100%;
        background: #adffe6;
        border: 4px solid #707070;
        border-radius: 2px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
      .word /deep/ table {
        width: 100% !important;
        box-sizing: border-box !important;
        border-right: 1px solid grey !important;
        border-bottom: 1px solid grey !important;
        border-spacing: 0px !important;
        tbody {
          border-spacing: 0px !important;
        }
        tbody tr:nth-child(1) {
          text-align: center !important;
        }
        th {
          padding: 4px !important;
          font-size: 15px !important;
          font-weight: bold !important;
          border: 1px solid grey !important;
        }
        tr {
          border-color: inherit !important;
          border: 1px solid grey !important;
        }
        td {
          padding: 4px !important;
          font-size: 14px !important;
          border-top: 1px solid grey !important;
          border-left: 1px solid grey !important;
          text-align: center !important;
        }
      }

      .imgbox {
        margin-top: 20px;
        flex-wrap: wrap;
        .video {
          width: 100%;
          height: 100%;
        }
        .img_item {
          width: 180px;
          height: 180px;
          background: #adffe6;
          border: 4px solid #707070;
          border-radius: 2px;
          margin-left: 10px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .img_new {
          width: 98%;
          height: 100%;
          margin: 10px auto;
        }
        .video_new {
          width: 98%;
          height: 400px;
          margin: 10px auto;
        }
      }
      .cun_title {
        width: 20px;
        height: 20px;
      }
    }
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 7px;
      z-index: 100001;
      font-size: 29px;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
    }
  }
  .new_inner {
    top: 12%;
    margin: 0;
    .new_box {
      max-height: 800px;
    }
  }
}
.box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(29, 133, 197, 1);
  -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1);
  background: #535353;
}
.box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #ededed;
}
.dyth {
  font-size: 14px;
  text-align: center;
  padding: 5px;
  color: red;
}
.dytd {
  text-align: center;
  padding: 5px;
}
.redcolor {
  color: red !important;
}
.jieimg {
  max-width: 100%;
  height: auto;
}
</style>
<style lang="scss">
.el-table {
  background-color: transparent !important;
}
.el-table th.el-table__cell {
  background-color: transparent !important;
}
.el-table tr {
  background-color: transparent !important;
}
.el-table .cell {
  color: #fff;
}
.el-table th.el-table__cell > .cell {
  color: #fff;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: transparent !important;
}
// .el-table__row>td{
//     border-bottom: 1px solid rgba(255, 255, 255, 0.1)!important;
// }
.el-table .el-table__body-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px !important; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  background: #535353 !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px !important;
  background: #ededed !important;
}
</style>
