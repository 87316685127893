import '@/common/css/common.scss';
import mixins from '@/common/mixins/mixins.js';
import SzMode from '@/components/SzMode';
import tFooter from '@/components/footer.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment';
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import VScaleScreen from 'v-scale-screen';
import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
// import VueResource from 'vue-resource'//引入VueResource对象来自'./vue-resource'
// import VueRouter from 'vue-router' //引入VueRouter对象来自'./vue-router'
//引入vuex状态管理模式
import dataV from '@jiaminghi/data-view';
import store from './store/index';

Vue.use(dataV)


Vue.component('t-footer', tFooter)
Vue.component('sz-mode', SzMode)

Vue.use(VScaleScreen)
Vue.use(ElementUI)
Vue.prototype.$moment = moment;
Vue.mixin(mixins)
Vue.config.productionTip = false
Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}
// Vue.prototype.mars3d = mars3d
// Vue.prototype.Cesium = mars3d.Cesium

//即时获取sessionStorage,实现顶部组件内登录状态
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'userInfo') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
