

export let baroption = {
  color: ['#FFB74D', '#FFF176', '#AED581', '#4DB6AC', '#4FC3F7'],
  title: {
    text: "价格涨幅排名统计图",
    textStyle: {
      color: '#fff'
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    top: '15%',
    left: '10%',
    right: '15%',
    bottom: '5%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    show: true,
    axisLine: {
      show: true,
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false,
      color: '#fff',
      textStyle: {
        fontSize: 12,
      },
    },
    splitLine: {
      show: false,
    },
    data: []
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#fff',
      textStyle: {
        fontSize: 12,
      },
    },
    splitLine: {
      show: false,
    },
    axisLine: {
      show: true,
    },

    axisTick: {
      show: false
    },
    data: []
  },
  series: [
    {
      name: '',
      type: 'bar',
      data: [],
      barWidth: 14,
      // barGap: 5,
      label: {   //设置柱状图上显示  对应值
        show: true,
        formatter: '{b} \n\r {c}',
        position: 'top',
        color: 'rgba(255, 255, 255, 1)',
      },
      itemStyle: {
        color: function (params) {
          if (params.data > 0) {
            return 'rgb(61,183,104)';
          } else {
            return '#c44545';
          }
        }
      }
    },

  ]
},

  pieoption = {
    title: {
      text: '各市场菜品占有量统计图',
      show: true,
      textStyle: {
        color: '#fff',
        x: 'center',
        fontSize: 18,
        fontWeight: 'normal'
      },
      // x:'center'
    },
    tooltip: {
      show: false,
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    color: ['#4fc3f7', '#fff176', '#ffb74d', '#aed581', '#4db6ac', '#7986cb', '#ba68c8', '#e57373'],
    legend: {
      orient: "vertical",
      show: false,
      itemGap: 10,
      itemWidth: 10,
      itemHeight: 10,
      right: '10',
      bottom: '0',
      textStyle: {
        color: '#fff',
        fontSize: 16,
      }
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['45%', '65%'],
        center: ["50%", "50%"],
        avoidLabelOverlap: false,
        labelLine: {
          //图形外文字线
          normal: {
            length: 20,
            length2: 60,
          },
        },
        label: {
          normal: {
            // formatter: '{b}:{c}: ({d}%)',
            formatter: '{d}%  \n {b}', //图形外文字上下显示
            borderWidth: 20,
            borderRadius: 4,
            padding: [0, -60], //文字和图的边距
            rich: {
              a: {
                fontSize: 12,
                lineHeight: 22,
              },
              b: {
                //name 文字样式
                fontSize: 12,
                lineHeight: 22,
              },
              c: {
                //value 文字样式
                fontSize: 12,
                lineHeight: 22,
                align: 'center',
              },
            },
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '16',
          }
        },
        data: []
      }
    ]
  }
